<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="senderCollapse">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Redirections</h3>
        </div>
        <vs-row class="success-redirect">
          <vs-row>
            <vs-col vs-w="12" class="success-redirect-heading">
              <h5 class="mt-4 success-trans">Successful transactions</h5>
              <hr class="line-hr mb-6 md:mb-8 ">
            </vs-col>
            <vs-col vs-w="12" class="success-redirect-text">
              <p>Set up where you would like to redirect customers after they successfully complete payment.</p>
            </vs-col>
          </vs-row>

          <vs-row class="mt-8 input-space">
            <div class="input-grp mb-6 md:mb-8">
              <span class="text-danger text-sm" v-if="errors.has('SuccessHeading')">{{ errors.first("SuccessHeading") }}</span>
              <vs-input class="w-full" v-validate="'min:3'" size="large" v-model="redirectData.success.heading" name="SuccessHeading" id="SuccessHeading" />
              <label for="" class=" w-full font-light">Heading</label>
            </div>
            <div class="input-grp mb-6 md:mb-8" >
              <span class="text-danger text-sm" v-if="errors.has('SuccessMessage')">{{ errors.first("SuccessMessage") }}</span>
              <vs-textarea
                class="w-full"
                v-validate="'min:3|max:250'"
                size="large"
                v-model="redirectData.success.message"
                counter="250"
                maxlength="250"
                name="SuccessMessage" id="SuccessMessage"
                @input="auto_grow('SuccessMessage')"/>

                <label for="" class=" w-full font-light">Message</label>
            </div>
          </vs-row>

          <vs-row class="input-space mt-2">
            <div class="input-grp mb-6">
              <span class="text-danger text-sm" v-if="errors.has('SuccessButtonText')">{{ errors.first("SuccessButtonText") }}</span>
              <vs-input class="w-full" v-validate="'min:3|max:70'" size="large" v-model="redirectData.success.btntext"  name="SuccessButtonText" id="SuccessButtonText" />
              <label for="" class=" w-full font-light">Redrection button text</label>
            </div>
            <div  class="input-grp">
              <span class="text-danger text-sm" v-if="errors.has('SuccessLink')">{{ errors.first("SuccessLink") }}</span>
              <vs-input class="w-full" v-validate="'min:3|url'" size="large" v-model="redirectData.success.link" name="SuccessLink" id="SuccessLink" />
              <label for="" class=" w-full font-light">Redirection URL</label>
            </div>
          </vs-row>

        </vs-row>
        <vs-row v-if="paymentOptionData.requestOptions.includes('pay-later')" class="failed-redirect mt-8">
          <vs-row>
            <vs-col vs-w="12" class="success-redirect-heading">
              <h5 class="mt-4 success-trans">ID verification failed</h5>
              <hr class="line-hr mb-8">
            </vs-col>

            <vs-col vs-w="12" class="success-redirect-text">
              <p>Set up where you would like to redirect customers if they fail ID Verification for Pay Later payments.</p>
            </vs-col>
          </vs-row>

          <vs-row class=" mt-4 input-space">
            <div class="input-grp my-4">
              <label for="" :class="redirectData.fail.enablepaynow ? 'disableClass' : ''" class="w-full font-light">Heading</label>
              <vs-input :disabled="redirectData.fail.enablepaynow" class="w-full" v-validate="'min:3'" size="large" v-model="redirectData.fail.heading" name="FailHeading" id="FailHeading" />
              <span class="text-danger text-sm" v-if="errors.has('FailHeading')">{{ errors.first("FailHeading") }}</span>
            </div>

            <div class="input-grp my-4" >
              <label for="" :class="redirectData.fail.enablepaynow ? 'disableClass' : ''" class=" w-full font-light">Message</label>
              <vs-textarea
                counter="250"
                maxlength="250"
                class="w-full"
                v-validate="!redirectData.fail.enablepaynow?{min:3,max:250}:''"
                size="large"
                :disabled="redirectData.fail.enablepaynow"
                v-model="redirectData.fail.message"
                name="FailMessage" id="FailMessage"
                @input="auto_grow('FailMessage')"/>

              <span class="text-danger text-sm" v-if="errors.has('FailMessage')">{{ errors.first("FailMessage") }}</span>
            </div>
          </vs-row>

          <vs-row class=" mt-2 input-space">
            <div  class="input-grp my-4">
              <label for="" :class="redirectData.fail.enablepaynow ? 'disableClass' : ''" class="w-full font-light">Redrection button text</label>
              <vs-input :disabled="redirectData.fail.enablepaynow" class="w-full" v-validate="!redirectData.fail.enablepaynow?{min:3,max:70}:''" size="large" v-model="redirectData.fail.btntext"
                name="FailButtonText" id="FailButtonText" />

              <span class="text-danger text-sm" v-if="errors.has('FailButtonText')">{{ errors.first("FailButtonText") }}</span>
            </div>

            <div  class="input-grp  my-4">
              <label for="" :class="redirectData.fail.enablepaynow ? 'disableClass' : ''"  class="w-full font-light">Redirection URL</label>
              <vs-input :disabled="redirectData.fail.enablepaynow" class="w-full" v-validate="!redirectData.fail.enablepaynow?{min:3,url:true}:''" size="large" v-model="redirectData.fail.link"
                name="FailLink" id="FailLink" />

              <span class="text-danger text-sm" v-if="errors.has('FailLink')">{{ errors.first("FailLink") }}</span>
            </div>
          </vs-row>
        </vs-row>

      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
  import { Validator } from "vee-validate";

  const dictionary = {
    en: {
      attributes: {
        SuccessHeading: "heading",
        SuccessButtonText: "button text",
        SuccessMessage: "message",
        SuccessLink: "redirection url",

        FailHeading: "heading",
        FailButtonText: "button text",
        FailMessage: "message",
        FailLink: "redirection url",
      },
    },
  };
  Validator.localize(dictionary);

  export default {
    name: "Redirection",
    props: ['redirectData', "paymentOptionData", "paymentDataDefault", "editFlag"],
    data() {
      return {

      }
    },
    watch:{
      "paymentOptionData.requestOptions"(val) {
        if (val) {
          if (!this.editFlag) {
            this.$refs.senderCollapse.maxHeight = "none !important";
          }
        }
      },
      isFormDirty(val) {
        if (val) {
          this.$emit("checkDirty", this.isFormDirty);
        }
      }
    },
    methods: {
      auto_grow(elem) {
        var element = document.getElementById(elem);
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
        this.$refs.senderCollapse.maxHeight = "none !important";
      }
    }
  }
</script>