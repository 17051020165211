<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="notificationCollapse">
        <div slot="header">
          <h3 class="text-left card-title">Notifications</h3>
        </div>
        <p class="mb-8">Where would you like email notifications to be sent?</p>

        <vs-row class="notific-cards">
          <vs-col class="spacing-radio w-auto">
            <div class="radio-card notification-radio space-below" @click="activateNotify(true)" :class="{ active: active_el == true }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Request sender</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultEmailNotification" vs-value="1" vs-name="notification"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Send notifications to the person that sends the payment request only.</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
          <vs-col class="w-auto">
            <div class="radio-card notification-radio" @click="activateNotify(false)" :class="{ active: active_el == false }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Other</vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2">
                  <vs-radio v-model="defaultEmailNotification" vs-value="0" vs-name="notification"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Enter an email address to receive notifications for this template. Users can opt to also receive notifications
                  directly.</span>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
        <vs-row v-if="!defaultEmailNotification" class="mb-6">
          <vs-col vs-xs="12" vs-sm="8" vs-lg="4">
            <label for="emailAddress" class="block w-full text-xm font-normal mt-8 pb-3 text-base">Email address <span class="mid-blue">*</span></label>
              <div v-for="(newEmail, index) in multipleEmailAddress" :key="index" class="flex flex-row justify-between mb-4 md:mb-6">
                <div class="mr-8 w-full">
                <vs-input size="large" v-model="newEmail.email" data-vv-validate-on="blur" data-vv-as="email address" :name="'newEmail'+index"
                  :id="'multipleEmailAddress'+index" class="w-full" v-validate="defaultEmailNotification === false ? 'required|email' : ''"
                  @keypress="changesEmail" @change="changesEmail"/>
                <span class="text-danger text-sm" v-show="errors.has('emailAddress')">{{ errors.first("emailAddress") }}</span>
                <span class="text-danger text-sm" v-show="errors.has('newEmail'+index)">{{ errors.first('newEmail'+index) }}</span>
                </div>
                <feather-icon v-if="multipleEmailAddress.length > 1"  icon="Trash2Icon" svgClasses="h-6 w-6 hover:text-danger cursor-pointer" @click="removeEmail(index)" />
              </div>
              <span @click="addEmail" v-if="multipleEmailAddress.length <=4 " class="text-link flex flex-row pt-2 mb-6 md:mb-8"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add email address</span>
          </vs-col>
        </vs-row>
        <h5 class="notificaton-title mt-8 mb-0 border-0">Payment request notifications</h5>
        <hr class="line-hr mb-8" />
        <vs-table :data="prNotification" class="mb-10 notific-table" :disabled="notificationDisabled">
          <template slot="thead">
            <vs-th class="force-th-width v-baseline-aligned">Status</vs-th>
            <vs-th class='v-baseline-aligned'>Description</vs-th>
            <vs-th class="v-baseline-aligned">
              <div class="flex flex-col items-center">
                <label for="prNotifications_forEmail" class="w-full pr-0 font-normal">Enable all</label>
                <vs-switch id="prNotifications_forEmail" :color="switchColor" v-model="prCheckbox.forEmail" @click="check($event, prCheckbox.forEmail)" 
                  :disabled="notificationDisabled"/>
              </div>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td v-if="tr.display" :data="tr.status">{{ tr.status }}</vs-td>
              <vs-td v-if="tr.display" :data="tr.description">{{ tr.description }}</vs-td>
              <vs-td v-if="tr.display" :data="tr.forEmail">
                <div class="flex justify-center">
                  <vs-checkbox v-model="tr.forEmail" :disabled="notificationDisabled"></vs-checkbox>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <h5 class="notificaton-title mt-6 mb-0 border-0">Transaction notifications</h5>
        <hr class="line-hr mb-8" />
        <vs-table :data="txNotification" class="notific-table" :disabled="notificationDisabled">
          <template slot="thead">
            <vs-th class="v-baseline-aligned">Status</vs-th>
            <vs-th class="v-baseline-aligned">Description</vs-th>
            <vs-th class="v-baseline-aligned">
              <div class="flex flex-col items-center">
                <label for="txNotifications_forEmail" class="w-full font-normal">Enable all</label>
                <vs-switch id="txNotifications_forEmail" :color="switchColor" v-model="trCheckbox.forEmail" @click="check($event, trCheckbox.forEmail)"
                  :disabled="notificationDisabled"/>
              </div>
            </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="tr.status">{{ tr.status }}</vs-td>
              <vs-td :data="tr.description">{{ tr.description }}</vs-td>
              <vs-td :data="tr.forEmail">
                <div class="flex justify-center">
                  <vs-checkbox v-model="tr.forEmail" :disabled="notificationDisabled" @change="checkEnableAll('TX')"></vs-checkbox>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>
<script>
import { EditIcon,PlusIcon } from "vue-feather-icons";

export default {
  components:{PlusIcon, EditIcon},
  props: [
    "notification",
    "prNotificationsCheckbox",
    "transactionNotificationsCheckbox",
    "isSubmitted",
    "paymentOptionData",
  ],
  data() {
    return {
      active_el: true,
      defaultEmailNotification: true,
      notificationDisabled: false,
      switchColor: "#0F67F4",
      trCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prNotification: [],
      txNotification: [],
      defaultNotification: {
        txNotification: [],
        prNotification: [],
      },
      multipleEmailAddress: [],
    };
  },
  mounted() {
    this.prNotification = this.notification.prNotification;
    this.txNotification = this.notification.txNotification;

    // store for later use
    this.defaultNotification.prNotification = this.prNotification;
    this.defaultNotification.txNotification = this.txNotification;

    this.trCheckbox = this.transactionNotificationsCheckbox;
    this.prCheckbox = this.prNotificationsCheckbox;
    this.active_el = this.notification.requestSenderEmail;
    this.defaultEmailNotification = this.notification.requestSenderEmail;
    if (this.notification && this.notification.multipleEmailAddress && this.notification.multipleEmailAddress.length > 0) {
      this.multipleEmailAddress= this.notification.multipleEmailAddress.map(item=> {return {email: item}});
    }
    else {
      this.multipleEmailAddress = [{email: ""}];
    }
    this.checkNotification();
  },
  watch: {
    notification: async function (val) {
      if (val.prNotification) {
        this.prNotification = val.prNotification;
      }
      if (val.txNotification) {
        this.txNotification = val.txNotification;
      }
    },
    prNotificationsCheckbox: async function (val) {
      if (val) {
        this.prCheckbox = val;
      }
    },
    transactionNotificationsCheckbox: async function (val) {
      if (val) {
        this.trCheckbox = val;
      }
    },
    async isSubmitted(value) {
      this.startValidating();
    },
    "paymentOptionData.requestType"(val) {
      if (val) {
        this.checkNotification();
      }
    },
    "paymentOptionData.requestOptions"(val) {
      if (val) {
        this.checkNotification();
      }
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },
  methods: {
    removeEmail(index) {
      this.multipleEmailAddress.splice(index, 1);
      this.notification.multipleEmailAddress = this.multipleEmailAddress;
      const key = this.errors.items.findIndex(object => { return object.field === 'newEmail'+index });
      if(key >= 0) {
        this.errors.items.splice(key, 1);
      }
    },
    changesEmail(val) {
      this.notification.multipleEmailAddress = this.multipleEmailAddress;
    },
    addEmail() {
      this.multipleEmailAddress.push({email: ''});
    },
    checkEnableAll(type) {
      const notification = type === "TX" ? this.txNotification : this.prNotification;
      if (type === "PR") {
        if (this.beChecked(notification, "forEmail")) {
          this.prCheckbox.forEmail = true;
        } else {
          this.prCheckbox.forEmail = false;
        }
      } else {
        if (this.beChecked(notification, "forEmail")) {
          this.trCheckbox.forEmail = true;
        } else {
          this.trCheckbox.forEmail = false;
        }
      }
    },
    activateNotify(el) {
      this.active_el = el;
      this.defaultEmailNotification = el;
      this.$refs.notificationCollapse.maxHeight = "none !important";
      this.$emit("changeNotification", el);
    },
    checkNotification() {
      const defaultPrNotification = this.$lodashHelper.checkObjectKey(this.defaultNotification, "prNotification", []);
      if (this.paymentOptionData.requestType == "one-off") {
        if (this.paymentOptionData.requestOptions.includes("pay-now") && !this.paymentOptionData.requestOptions.includes("pay-later")) {
          this.prNotification = defaultPrNotification.filter((el) => el.status != "Extended" && el.status != "Info required" && el.status != "Active");
          this.txNotification = this.defaultNotification.txNotification;

          const refundStatus = this.defaultNotification.txNotification.find((el) => el.status === "Refunded");
          if (refundStatus) {
            refundStatus.forDashboard = true;
            refundStatus.forEmail = true;
          }
        }

        if (this.paymentOptionData.requestOptions.includes("pay-later") && !this.paymentOptionData.requestOptions.includes("pay-now")) {
          this.prNotification = this.defaultNotification.prNotification;
          this.txNotification = this.defaultNotification.txNotification.filter((el) => el.status != "Refunded");
        }

        if (this.paymentOptionData.requestOptions.includes("pay-later") && this.paymentOptionData.requestOptions.includes("pay-now")) {
          this.prNotification = this.defaultNotification.prNotification;
          this.txNotification = this.defaultNotification.txNotification;

          const refundStatus = this.defaultNotification.txNotification.find((el) => el.status === "Refunded");

          refundStatus.forDashboard = true;
          refundStatus.forEmail = true;
        }
      } else {
        // for recurring
        this.prNotification = defaultPrNotification.filter((el) => el.status != "Extended" && el.status != "Info required");
        this.txNotification = this.defaultNotification.txNotification;

        const refundStatus = this.defaultNotification.txNotification.find((el) => el.status === "Refunded");

        refundStatus.forDashboard = true;
        refundStatus.forEmail = true;
      }
    },
    check(e, _newVal) {
      _newVal = !_newVal;
      const info = e.target.id.split("_");
      const [type, field] = info;
      this.$emit("checkDirty", true);
      if (type.includes("tx")) {
        this.txNotification = this.checkUncheck(_newVal, this.txNotification, field);
        return;
      }

      this.prNotification = this.checkUncheck(_newVal, this.prNotification, field);
    },
    beChecked(value, field) {
      return value.every((el) => el[field]);
    },
    checkUncheck(ischecked, values, field) {
      return values.map((el) => {
        el[field] = ischecked;
        return el;
      });
    },
    async startValidating() {
      await this.$validator.validate();
    },
  },
  created(){
    this.requestOptions = this.$store.state.AppActiveUser.requestOptions;
    const defaultTxNotification = this.$lodashHelper.checkObjectKey(this.notification, "txNotification", []);
    this.txNotification = defaultTxNotification.filter((notificationPR) => {
      return (this.requestOptions.includes("pay-now") && notificationPR.status !== 'ID verification failed' || this.requestOptions.includes("pay-later"));
    });
  }
};
</script>
