<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item :open="!editFlag ? true : false" ref="paymentCollapse">
        <div slot="header">
          <h3 class="text-left card-title">Payment options <span class="text-2xl mid-blue">*</span></h3>
        </div>
        <div>
          <vs-row>
            <vs-col v-if="requestTypeArray.includes('one-off') && !paylaterOnly" class="lg:mr-6 md:mr-5 sm:mr-5 mr-0 w-auto">
              <div class="radio-card space-below" @click="activate(true)" :class="{ active: active_el === true }">
                <vs-row class="justify-between">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">One-off</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center">
                    <vs-radio v-model="requestType" vs-value="1" vs-name="requestType" name="requestType" id="requestType"></vs-radio>
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <span class="radio-info">One-time transaction</span>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
            <vs-col class="w-auto" v-if="requestTypeArray.includes('recurring') && givePayNowOption">
              <div class="radio-card" @click="activate(false)" :class="{ active: active_el === false }">
                <vs-row class="justify-between">
                  <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Recurring</vs-col>
                  <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center">
                    <vs-radio v-model="requestType" vs-value="0" vs-name="requestType" name="requestType"></vs-radio>
                  </vs-col>
                </vs-row>
                <vs-row>
                  <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                    <span class="radio-info">Recurring payments deducted on a schedule</span>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
            <vs-col vs-w="12">
              <span class="text-danger text-sm" v-show="errors.has('requestType')">{{ errors.first("requestType") }}</span>
            </vs-col>
          </vs-row>
        </div>
        <template v-if="defaultData.requestType == 'one-off' && !payNowOnly">
          <hr v-if="givePayLaterOption" class="line-hr my-8" />
          <vs-row>
            <vs-col v-if="givePayLaterOption" class="pay-now-heading" vs-w="12">
              <h6 class="mb-6 body-grey font-light text-base">What payment options would you like to offer the customer?</h6>
            </vs-col>
            <div class="flex flex-wrap">
              <div class="offer-pays lg:mr-5 md:mr-5 sm:mr-5 mr-0 space-below" v-if="givePayNowOption && productConfigType == 'THREE_PARTY'">
                <div @click="selectOption('pay-now', true)" class="w-full" :class="{ active: requestOptionArray.includes('pay-now') }">
                  <checkbox
                    v-model="defaultData.requestOptions"
                    :name="'pay-now'"
                    :radioValue="'pay-now'"
                    :value="'pay-now'"
                    :title="'Pay Now'"
                    :description="'Customer pays full amount immediately'"
                    @input="selectOption('pay-now')"
                    :isChecked="requestOptionArray.includes('pay-now')"
                  ></checkbox>
                </div>
              </div>
              <div class="offer-pays lg:mr-5 md:mr-5 sm:mr-5 mr-0 space-below" v-if="givePayNowOption && productConfigType == 'TWO_PARTY'">
                <div class="w-full">
                  <div class="radiobox sm:justify-center radio-default">
                    <div class="radio-wrapper">
                      <div class="title flex items-center">
                        <div class="font-normal capitalize radio-label text-xl mb-0">Pay Now</div>
                      </div>
                    </div>
                    <div class="desc">
                      <p class="text-xs">Customer pays full amount immediately</p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="givePayLaterOption" class="offer-pays">
                <div @click="selectOption('pay-later', true)" class="w-full" :class="{ active: requestOptionArray.includes('pay-later') }">
                  <checkbox
                    v-model="defaultData.requestOptions"
                    :name="'pay-later'"
                    :radioValue="'pay-later'"
                    :value="'pay-later'"
                    :title="'Pay Later'"
                    @input="selectOption('pay-later')"
                    :description="'Customer pays over multiple payments on a payment plan'"
                    :isChecked="requestOptionArray.includes('pay-later')"
                  >
                  </checkbox>
                </div>
              </div>
            </div>
          </vs-row>
        </template>

        <template>
          <vs-row>
            <vs-col v-if="paymentDataDefault.payLater && requestOptionArray.includes('pay-later')" class="mt-6" vs-w="3" vs-sm="12">
              <label class="w-full text-base font-light">Pay Later payment plan</label>
              <vs-select autocomplete class="selectExample mt-2" v-model="defaultData.payLaterPlan" @change="changeProductConfig">
                <vs-select-item :key="index" :value="item.planId" :text="item.title" v-for="(item, index) in productOptions" />
              </vs-select>
            </vs-col>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <span class="text-danger text-sm" v-show="errors.has('paylaterPlans')">{{ errors.first("paylaterPlans") }}</span>
            </vs-col>
          </vs-row>
        </template>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
import radiobox from "../../../../components/radiobox.vue";
import checkbox from "../../../../components/checkbox.vue";
import DatePicker from "vue2-datepicker";
import { Validator } from "vee-validate";
import { RRule } from "rrule";
import moment from "moment";

const dictionary = {
  en: {
    attributes: {
      paylaterPlans: "pay later plan",
      startDate: "start date",
      interval: "payment interval",
      everySelect: "every week/month",
    },
  },
};
Validator.localize(dictionary);

export default {
  components: {
    radiobox,
    checkbox,
    DatePicker,
  },
  props: [
    "paymentOptionData",
    "isSubmitted",
    "paymentDataDefault",
    "paymentOptionFrequency",
    "editFlag",
    "productConfigType",
    "planOptions"
  ],
  data() {
    return {
      active_el: "",
      productOptions: [],
      defaultData: {},
      frequency: {},
      partnerId: JSON.parse(localStorage.getItem("user")).userType == "admin" ? JSON.parse(localStorage.getItem("user"))._id : JSON.parse(localStorage.getItem("user")).partnerId,
      // for paylater Plan
      everyOptions: [{ text: "Weekly", value: "week" }],
      isValid: false,
      paymentSchedules: [],
      totalNumberOfPayments: 0,
      requestType: "",
      allPlansSelected: false,
      flag: true,
      givePayNowOption: false,
      givePayLaterOption: false,
      payNowOnly:false,
      paylaterOnly :false
    };
  },
  watch: {
    async isSubmitted(value) {
      this.startValidating();
    },

    "frequency.period"(val) {
      this.paymentSchedules = [];
      this.prepareSchedule();
    },
    "frequency.frequency": function (val) {
      this.prepareSchedule();
    },
    "frequency.startDate": function (val) {
      this.prepareSchedule();
    },
    "frequency.endType": function (val) {
      if (val === "by") {
        this.frequency.endPayments = "";
      } else {
        this.frequency.endDate = "";
      }
      this.prepareSchedule();
    },
    "frequency.endDate": function (val) {
      this.prepareSchedule();
    },
    "frequency.endPayments": function (val) {
      this.prepareSchedule();
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    },
  },

  methods: {
    prepareSchedule() {
      let rule = { dtstart: this.frequency.startDate ? this.frequency.startDate : new Date() };

      if (this.frequency.period === "week") {
        rule.freq = RRule.WEEKLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      } else {
        rule.freq = RRule.MONTHLY;
        rule.interval = this.frequency.frequency ? parseInt(this.frequency.frequency) : 0;
      }

      if (this.frequency.endType === "by") {
        rule.until = this.frequency.endDate ? this.frequency.endDate : new Date();
      } else {
        rule.count = this.frequency.endPayments ? parseInt(this.frequency.endPayments) : 1;
      }
      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentList(rule.all());
    },

    paymentList(schedule) {
      let list = [];
      if (schedule.length > 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        list.push({
          label: "Second payment",
          value: schedule[1],
        });
        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 1) {
        list.push({
          label: "Payment",
          value: schedule[0],
        });
      }
      this.totalNumberOfPayments = schedule.length;

      this.paymentSchedules = list;
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    getWeekDay(date) {
      return moment(date).format("dddd");
    },

    async startValidating() {
      const valid = await this.$validator.validate();
      if (this.paymentOptionData.requestType == "") {
        this.errors.add({
          field: "requestType",
          msg: "Please select one option",
        });
      }
      this.$refs.paymentCollapse.maxHeight = "none !important";
    },
    submittedFalse() {
      this.isSubmitted = false;
    },
    activate(el) {
      this.active_el = el;
      if (el == true) {
        this.requestType = 1;
        this.defaultData.requestType = "one-off";
        this.paymentOptionData.requestOptions =[];
      } else {
        this.requestType = 0;
        this.defaultData.requestType = "recurring";
        this.defaultData.payLaterPlan= "";
        this.paymentOptionData.requestOptions =['pay-now'];
      }

      if (this.errors.has("requestType")) {
        this.$validator.errors.remove("requestType");
      }

      if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions[0] == "pay-now" && this.paymentDataDefault.requestOptions.length == 1) {
        this.defaultData.requestOptions = ['pay-now'];
      }

      this.$refs.paymentCollapse.maxHeight = "none !important";
      this.$emit("checkDirty", true);
    },
    selectOption(val, bool) {
      if (bool == true) {
        if (this.defaultData.requestOptions && this.defaultData.requestOptions.includes(val)) {
          const objIndex = this.defaultData.requestOptions.findIndex((obj) => obj === val);
          this.defaultData.requestOptions.splice(objIndex, 1);
        } else {
          this.defaultData.requestOptions.push(val);
        }
      } else {
        //added else condition here to get reverse state of array since this method is called twice if checkbox is clicked
        if (!this.defaultData.requestOptions.includes(val)) {
          this.defaultData.requestOptions.push(val);
        } else {
          const objIndex = this.defaultData.requestOptions.findIndex((obj) => obj === val);
          this.defaultData.requestOptions.splice(objIndex, 1);
        }
      }
      this.$refs.paymentCollapse.maxHeight = "none !important";
      this.$emit("checkDirty", true);
    },
    selectAllPlans() {
      this.allPlansSelected = !this.allPlansSelected;
      if (this.allPlansSelected) {
        this.defaultData.payLaterPlan = this.productOptions.map((element) => { return element.planId });
      } else {
        this.defaultData.payLaterPlan = [];
      }
    },
    checkSelected() {
      if (this.defaultData.payLaterPlan.length == this.productOptions.length) {
        this.allPlansSelected = true;
      } else {
        this.allPlansSelected = false;
      }
    },

    changeProductConfig() {
      const selectedData = this.productOptions.filter((element) => element.planId == this.defaultData.payLaterPlan);
      this.$emit("updateProductConfig", selectedData[0].productConfigType);
      if (selectedData[0].productConfigType == 'TWO_PARTY' && this.defaultData.requestOptions.includes("pay-now")) {
        this.defaultData.requestOptions = ['pay-later'];
      }
      this.checkSelected();
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    requestTypeArray() {
      const requestType = this.$lodashHelper.checkObjectKey(this.paymentDataDefault, 'requestTypes', []);
      if (Array.isArray(requestType)) {
        return requestType;
      }
      return [];
    },
    requestOptionArray() {
      const requestOption = this.$lodashHelper.checkObjectKey(this.defaultData, 'requestOptions', []);
      if (Array.isArray(requestOption)) {
        return requestOption;
      }
      return [];
    }
  },
  async mounted() {
    if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions.includes("pay-now")) {
      this.givePayNowOption = true;
    }
    if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions.includes("pay-later")) {
      this.givePayLaterOption = true;
    }

    if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions[0] == "pay-now" && this.paymentDataDefault.requestOptions.length == 1) {
      this.payNowOnly = true;
    }

    this.productOptions = [...this.planOptions];
    this.defaultData = this.paymentOptionData;

    if (this.paymentDataDefault.requestOptions && this.paymentDataDefault.requestOptions[0] == "pay-later" && this.paymentDataDefault.requestOptions.length == 1) {
      this.payNowOnly = false;
      this.paylaterOnly = true;
      this.givePayLaterOption = false;
      this.defaultData.requestOptions = ['pay-later'];
      this.defaultData.requestType = "one-off";
    }

    if (this.paymentOptionData.requestType == "one-off") {
      this.active_el = true;
      this.requestType = 1;
    } else if (this.paymentOptionData.requestType == "recurring") {
      this.active_el = false;
      this.requestType = 0;
    }
    this.frequency = this.paymentOptionData.recurringSchedule;

    if (!this.editFlag) {
      this.$refs.paymentCollapse.maxHeight = "none !important";
    }
    this.checkSelected();
  },
};
</script>

