<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="customerMessage">
        <div slot="header">
          <h3 class="text-left card-title font-normal">Message to customer</h3>
        </div>

        <p class="mb-8">Add a customised message you would like customers to see at the top of the payment page before they enter their payment details.</p>

        <div class="card-body">
          <vs-row class="mt-5">
            <vs-col vs-lg="12">
              <div class="input-grp">
              <vs-textarea
                counter="250"
                maxlength="250"
                row="3"
                name="bodyText"
                v-model="customTextBlockData.body"
                id="customer-message"
                @input="auto_grow('customer-message')"
              ></vs-textarea>
              </div>
            </vs-col>
          </vs-row>
          <div class="mt-8 mb-5 flex flex-row">
            <div>
              <vs-checkbox v-model="customTextBlockData.allowOverride"></vs-checkbox>
            </div>
            <div>
              <span class="info text-black text-base font-normal">Allow individual users to change this message when sending payment requests</span>
            </div>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
import { Validator } from "vee-validate";
const dictionary = {
  en: {
    attributes: {
      bodyText: "body",
    },
  },
};
Validator.localize(dictionary);
export default {
  props: ["customTextBlockData"],
  watch: {
    "customTextBlockData.body": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("checkDirty", true);
      }
    },
    "customTextBlockData.allowOverride": function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("checkDirty", true);
      }
    },
  },
  methods: {
    auto_grow(elem) {
      var element = document.getElementById(elem);
      element.style.height = "5px";
      element.style.height = (element.scrollHeight)+"px";
      this.$refs.customerMessage.maxHeight = "none !important";
    }
  },
  updated() {
    this.auto_grow('customer-message');
  }
};
</script>