<template>
  <div class="req-temp-form">
    <form @submit.prevent="createTemplate" enctype="multipart/form-data">
      <vs-row id="profile-page" v-if="templateType == 'api'">
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse" class="pb-8">
                <div slot="header">
                  <h3 class="text-left card-title font-normal">Template details <span class="text-2xl mid-blue">*</span></h3>
                </div>
                <vs-row>
                  <vs-col vs-w="4">
                    <label class="w-full">Template name <span>*</span></label>
                    <vs-input type="text" v-model="templateName" v-validate="'required|max:30'" name="templateName" class="lg:w-2/4 md:w2/4 sm:w-1/2 w-full" id="templateName" />
                    <vs-col vs-type="flex" vs-align="center" vs-w="12">
                      <span class="text-danger text-sm mt-2" v-show="errors.has('templateName')">{{ errors.first("templateName") }}</span>
                    </vs-col>
                  </vs-col>
                  <vs-col vs-w="8">
                    <p><label>Description</label></p>
                    <vs-textarea type="text" v-model="templateDescription" name="templateDescription" class="lg:w-2/4 md:w2/4 sm:w-1/2 w-full" id="templateDescription"></vs-textarea>
                    <vs-col vs-type="flex" vs-align="center" vs-w="12">
                      <span class="text-danger text-sm mt-2" v-show="errors.has('templateDescription')">{{ errors.first("templateDescription") }}</span>
                    </vs-col>
                  </vs-col>
                  <vs-col vs-w="4" v-if="editFlag">
                    <label>Template ID</label>
                    <p class="mt-2">{{ templateId }}</p>
                  </vs-col>
                </vs-row>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>
      </vs-row>
      <vs-row id="profile-page" class="mt-5 sim-adv" v-else>
        <!-- My Details -->
        <vx-card class="accordin-card">
          <div class="vx-card__body bsv p-0">
            <vs-collapse accordion>
              <vs-collapse-item open ref="templateCollapse">
                <div slot="header">
                  <h3 class="text-left card-title font-normal">Template details <span class="text-2xl mid-blue">*</span></h3>
                </div>
                <div class="lg:flex lg:flex-row">
                  <div class="input-grp mb-6 lg:mb-0">
                    <span class="text-danger text-sm mt-2" v-if="errors.has('templateName')">{{ errors.first("templateName") }}</span>
                    <vs-input type="text" v-model="templateName" v-validate="'required|max:30'" name="templateName" class="w-full" id="templateName"/>
                    <label class="w-full">Template name</label>
                  </div>
                  <div class="input-grp" v-if="editFlag">
                    <label class="w-full">Template ID</label>
                    <p class="mt-2">{{ templateId }}</p>
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </vx-card>

        <PaymentOption
          :isSubmitted="isSubmitted"
          :paymentOptionData="paymentOptionData"
          :paymentDataDefault="paymentDataDefault"
          @checkDirty="checkDirty"
          :editFlag="editFlag"
          :productConfigType="productConfigType"
          :planOptions="planOptions"
          v-if="paymentBind"
          @updateProductConfig="changeProductConfig"
        ></PaymentOption>

        <vx-card class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item :open="!editFlag ? true : false" ref="linkedCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Linked account <span class="text-2xl mid-blue">*</span></h3>
              </div>
              <p v-if="editFlag && paymentOptionData.requestType == 'recurring'">
                You can't edit the linked account when the payment option for an
                existing template is set to Recurring. If you would like to use
                a different account, you'll need to create a new template.
              </p>
              <p class="mb-4" v-else>Nominate the bank account to which payments collected via this payment request will be directed.</p>
              <p class="textBlack mt-8" v-if="editFlag && paymentOptionData.requestType == 'recurring'">{{ linkedAccount.displayName }}</p>
              <vs-row v-else>
                <vs-col vs-type="flex" vs-align="center">
                  <vs-select
                    v-model="selectedAccount"
                    name="linkedAccount"
                    id="linkedAccount"
                    class="lg:w-1/4 md:w1/4 sm:w-1/2 w-full selectExample"
                    v-validate="'required'"
                    @change="changeConnector"
                    :placeholder="'Select account'"
                  >
                    <vs-select-item
                      :key="index"
                      :value="item.bankId"
                      :text="item.displayName ? item.displayName : item.accountName"
                      v-for="(item, index) in bankList"
                    />
                  </vs-select>
                </vs-col>
                <vs-col vs-type="flex" vs-align="center" vs-w="12">
                  <span class="text-danger text-sm" v-show="errors.has('linkedAccount')">{{ errors.first("linkedAccount") }}</span>
                </vs-col>
              </vs-row>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <vx-card class="accordin-card mt-5" v-if="productConfigType == 'THREE_PARTY'">
          <vs-collapse accordion>
            <vs-collapse-item ref="headerCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Branding</h3>
              </div>

              <p class="mb-8">This information will appear on payment requests sent from this template. Customers will see it when making a payment.</p>
              <page-header @checkToggle="partnerToggle" v-if="headerBind" :headerData="header" :logos="logoLibrary" @changeHeader="headerChange" @checkDirty="checkDirty"></page-header>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <CustomTextBlock :customTextBlockData="customTextBlockData" @checkDirty="checkDirty" v-if="productConfigType == 'THREE_PARTY'"></CustomTextBlock>

        <vx-card v-if="templateType == 'advance' && productConfigType == 'THREE_PARTY'" class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item ref="customCollapse">
              <div slot="header">
                <h3 class="text-left card-title font-normal">Custom fields</h3>
              </div>

              <p>You can create custom form fields to collect information related to this payment request.</p>
              <custom-fields v-if="customFieldBind" v-on:savedCustomField="pushToCustomFields($event)" :customFieldValue="customFieldList"></custom-fields>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <redirection
          :redirectData="redirectData"
          :paymentOptionData="paymentOptionData"
          :paymentDataDefault="paymentDataDefault"
          :editFlag="editFlag"
          @checkDirty="checkDirty"
          v-if="productConfigType == 'THREE_PARTY'"
        ></redirection>

        <vx-card v-if="templateType == 'advance' && productConfigType == 'THREE_PARTY'" class="accordin-card mt-5">
          <vs-collapse accordion>
            <vs-collapse-item>
              <div slot="header">
                <h3 class="text-left card-title font-normal">Request expiry time</h3>
              </div>

              <p class="mb-8">Set up how long you would like payment requests sent from this template to stay open before they expire.</p>
              <request-expiry v-if="expiryBind" :expiryData="requestExpiry" @changeExpiry="expiryChange" @checkDirty="checkDirty"></request-expiry>
            </vs-collapse-item>
          </vs-collapse>
        </vx-card>

        <notification
          v-if="notifyBind && (templateType == 'advance' || templateType == 'simple')"
          :isSubmitted="isSubmitted"
          :notification="notifications"
          :transactionNotificationsCheckbox="transactionNotificationsCheckbox"
          :prNotificationsCheckbox="prNotificationsCheckbox"
          :paymentOptionData="paymentOptionData"
          @changeNotification="notifyChange"
          @checkDirty="checkDirty"
        ></notification>

        <sender-information
          v-if="senderBind && productConfigType == 'THREE_PARTY'"
          :senderData="senderDetails"
          :isSubmitted="isSubmitted"
          :globalDefaultOverride="globalDefaultOverride"
          @changeSender="changeSender"
          @validateForm="formValidation"
          @checkDirty="checkDirty"
        ></sender-information>
      </vs-row>
      <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

      <pop-up :errorsList="errors" form-name="RequestTemplateForm"></pop-up>

      <vs-row class="mt-10">
        <div class="actions mt-5 text-right move-left">
          <vs-button v-if="editFlag" size="large" v-round class="only-border-btn mr-8" type="border" @click="fetchPendingPR">Delete Template</vs-button>
          <vs-button
            size="large"
            v-if="templateType == 'api'"
            class=""
            v-round
            :disabled="!validateForm || errors.items.length > 0"
            @click="createApiTemplate"
            >{{ saveTemplate }}</vs-button
          >
          <vs-button
            v-else
            size="large"
            v-round
            :disabled="!validateForm || errors.items.length > 0"
            @click="createTemplate"
            >{{ saveTemplate }}</vs-button
          >
          <a color="danger" @click="onCancel" class="ml-8">Cancel</a>
        </div>
      </vs-row>
    </form>
    <delete-popup
      :popup-active="popupDelActive"
      :warningContent="warningContent"
      :deleteWhat="deleteWhat"
      :buttonText="buttonText"
      :found="warningFound"
      @handleDeleteClick="delActions"
    ></delete-popup>
  </div>
</template>

<script>
import Redirection from "./redirection.vue";
import CustomTextBlock from "./CustomTextBlock.vue";
import PaymentOption from "./PaymentOption.vue";
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import PageHeader from "./Header.vue";
import RequestExpiry from "./RequestExpiry.vue";
import SenderInformation from "./SenderInformation.vue";
import Notification from "./Notification.vue";
import CustomFields from "./customFields.vue";
import DeletePopup from "@/views/components/DeletePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

const dictionary = {
  en: {
    attributes: {
      templateName: "template name",
      templateDescription: "template description",
      linkedAccount: "linked account",
    },
  },
};
Validator.localize(dictionary);
export default {
  mixins: [leavePopUpMixin],
  components: {
    Redirection,
    PopUp,
    LeavePopup,
    PageHeader,
    CustomTextBlock,
    RequestExpiry,
    SenderInformation,
    PaymentOption,
    Notification,
    CustomFields,
    DeletePopup,
  },
  data() {
    return {
      defaultLoadedConnector: {},
      defaultCommunication: {},
      redirectData: {
        success: {
          heading: "",
          btntext: "ID validation success",
          message: "",
          link: "",
        },
        fail: {
          heading: "",
          btntext: "ID validation failed",
          message: "",
          link: "",
          enablepaynow: false,
        },
      },

      templateType: "simple",
      templateActive: false,
      simpleAdvanceBox: false,

      popupActive: false, // determines if the leave popup is to be shown
      showValidationPopUp: false, // determines if the leave popup is to be shown
      nextObj: "", // determines the next route,
      isSaved: false,
      templateName: "",
      templateDescription: "",
      templateId: "",
      linkedAccount: {},
      selectedAccount: "",
      bankList: [],
      displayName: "",
      defaultHeader: {},
      header: {
        companyName: "",
      },
      logoLibrary: [],
      merchantId: "",
      headerBind: false,
      imageUrl: `${process.env.VUE_APP_API_URL}uploads/merchantlogo/`,
      isSubmitted: false,
      customFieldList: [],
      // custom text block
      customTextBlockData: {
        body: "",
        allowOverride: true,
      },
      // Payment options *
      paymentOptionData: {
        requestType: "",
        requestOptions: [],
        recurringSchedule: {
          period: "week", // month week data etc
          frequency: "", // number 1,2
          startDate: "",
          endDate: "",
          endType: "", // by , after
          endPayments: "", // number 1 /2
        },
        payLaterPlan: [],
        planDisplayName: "",
      },
      productConfigType: "THREE_PARTY",
      paymentDataDefault: JSON.parse(localStorage.getItem("user")).userType == "admin" ? JSON.parse(localStorage.getItem("user")) : JSON.parse(localStorage.getItem("user")).partner,
      expiryBind: false,
      defaultExpiryData: {},
      requestExpiry: {},
      pageRequestExpiry: {},
      senderBind: false,
      defaultSender: {},
      senderDetails: {},
      globalDefaultOverride: true,
      pageSender: {},
      paymentBind: false,
      notifyBind: false,
      customFieldBind: false,
      notifications: {
        requestSenderEmail: true,
        prNotification: [],
        txNotification: [],
      },
      transactionNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      prNotificationsCheckbox: {
        forEmail: "",
        forDashboard: "",
      },
      pageNotifications: {},
      pageTrNotify: {
        forEmail: "",
        forDashboard: "",
      },
      pagePrNotify: {
        forEmail: "",
        forDashboard: "",
      },
      id: "",
      editHeader: {},
      saveTemplate: "Create Template",
      editFlag: false,
      warningContent: "",
      deleteWhat: `template`,
      buttonText: `Delete`,
      popupDelActive: false,
      warningFound: false,
      childFormDirty: false,
      planOptions: [],
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin" ? this.user._id : this.user.partnerId;
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.templateType = this.$route.params.templateType ? this.$route.params.templateType : "simple";
    if (this.id) {
      this.saveTemplate = "Update Template";
      this.editFlag = true;
    }
  },
  methods: {
    ...mapActions("connector", ["fetchConnectorByPartnerId"]),
    ...mapActions("bank", ["fetchBanksAccountByPartnerId"]),
    ...mapActions("paymentRequestTemplate", [
      "createRequestTemplate",
      "fetchRequestTemplateByIdOnMerchant",
      "updateRequestTemplateById",
      "deleteRequestTemplateById",
      "fetchRequestTemplateByIdandStatus",
    ]),
    ...mapActions("logo", ["fetchLogosByMerchantId"]),
    ...mapActions("merchantGlobalSetting", [
      "fetchSettingsByMerchantId",
      "fetchNotification",
    ]),
    ...mapActions("partner", ["getPaymentPlanByMerchatId"]),

    async getPaylaterPlan() {
      await this.getPaymentPlanByMerchatId(this.partnerId)
        .then((result) => {
          if (result) {
            this.planOptions = result.data.data.payLaterPlans;
            if (this.editFlag) {
              const selectedPlan = this.planOptions.filter((element) => element.planId == this.paymentOptionData.payLaterPlan);
              this.productConfigType = selectedPlan[0].productConfigType;
            }
          }
        }).catch((ex) => {
          this.$vs.notify({
            title: "Payment plans",
            text: ex.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });
        });
    },

    async getConnectorsList(partnerId) {
      await this.fetchBanksAccountByPartnerId(partnerId)
        .then((result) => {
          if (result) {
            const banksList = result.data.data;
            this.defaultLoadedConnector = result.data.data;
            this.bankList = banksList.filter(item => item.isUsedForSettlement == true).map((item) => {
              return {
                bankId: item._id,
                displayName: item.displayName ? item.displayName : item.accountName,
              };
            });
          }
        }).catch((ex) => {
          this.$vs.notify({
            title: "Linked account",
            text: "We are unable to process your request at this time.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });
        });
    },

    async fetchTemplateDetails() {
      await this.fetchRequestTemplateByIdOnMerchant(this.id)
        .then((result) => {
          this.templateType = result.data.data[0].templateType;
          if (result.data.data[0].templateType) {
            this.templateActive = true;
          }

          this.redirectData = result.data.data[0].redirect;
          this.templateName = result.data.data[0].templateName;
          this.templateDescription = result.data.data[0].description;
          this.templateId = result.data.data[0].requestTemplateId;
          this.selectedAccount = result.data.data[0].linkedAccount.bankId;
          this.linkedAccount = result.data.data[0].linkedAccount;

          let headerFlag = result.data.data[0].header.isDefaultHeader;
          let selectedCompanyLogo = "";
          let selectedCompanyImage = "";
          let selectedPartnerLogo = "";
          let selectedPartnerImage = "";

          if (headerFlag) {
            selectedCompanyLogo = this.defaultHeader.companyLogo ? this.defaultHeader.companyLogo : undefined;
            selectedCompanyImage = this.defaultHeader.image ? this.defaultHeader.image : undefined;
            selectedPartnerLogo = this.defaultHeader.partnerLogo ? this.defaultHeader.partnerLogo : undefined;
            selectedPartnerImage = this.defaultHeader.partnerimage ? this.defaultHeader.partnerimage : undefined;
          } else {
            selectedCompanyLogo = result.data.data[0].header.companyLogo ? result.data.data[0].header.companyLogo._id : undefined;
            selectedCompanyImage = result.data.data[0].header.companyLogo ? result.data.data[0].header.companyLogo.logoImage : undefined;
            selectedPartnerLogo = result.data.data[0].header.partnerLogo ? result.data.data[0].header.partnerLogo._id : undefined;
            selectedPartnerImage = result.data.data[0].header.partnerLogo ? result.data.data[0].header.partnerLogo.logoImage : undefined;
          }

          this.editHeader = {
            isDefaultHeader: result.data.data[0].header.isDefaultHeader,
            abn: headerFlag ? this.defaultHeader.abn : result.data.data[0].header.abn,
            companyName: headerFlag ? this.defaultHeader.companyName : result.data.data[0].header.companyName,
            companyEmail: headerFlag ? this.defaultHeader.companyEmail : result.data.data[0].header.companyEmail,
            address: headerFlag ? this.defaultHeader.address : result.data.data[0].header.address,
            companyLogo: selectedCompanyLogo,
            image: selectedCompanyImage,
            partnerLogo: selectedPartnerLogo,
            partnerimage: selectedPartnerImage,
            partnerLogoEnabled: headerFlag ? this.defaultHeader.partnerLogoEnabled : result.data.data[0].header.partnerLogoEnabled,
            phone: headerFlag ? this.defaultHeader.phone : result.data.data[0].header.phone,
            website: headerFlag ? this.defaultHeader.website : result.data.data[0].header.website,
          };

          this.header = { ...this.editHeader };
          this.customTextBlockData = result.data.data[0].customText;
          this.requestExpiry = result.data.data[0].requestExpiry;
          this.pageRequestExpiry = { ...this.requestExpiry };
          this.pageSender = result.data.data[0].senderDetails;
          this.pageSender.senderType = result.data.data[0].senderDetails.senderType;

          if (this.pageSender.senderType == 1) {
            this.senderDetails = { ...this.defaultSender };
          } else {
            this.senderDetails = { ...this.pageSender };
          }

          this.pageNotifications = result.data.data[0].notifications;
          if (this.pageNotifications && this.pageNotifications.prNotification && this.pageNotifications.txNotification) {
            this.notifications = { ...this.pageNotifications };
          }
          this.pageTrNotify.forEmail = this.beChecked(result.data.data[0].notifications.txNotification, "forEmail");
          this.pageTrNotify.forDashboard = this.beChecked(result.data.data[0].notifications.txNotification, "forDashboard");
          this.pagePrNotify.forEmail = this.beChecked(result.data.data[0].notifications.prNotification, "forEmail");
          this.pagePrNotify.forDashboard = this.beChecked(result.data.data[0].notifications.prNotification, "forDashboard");
          this.transactionNotificationsCheckbox = { ...this.pageTrNotify };
          this.prNotificationsCheckbox = { ...this.pagePrNotify };
          if (!result.data.data[0].paymentOptions[0].payLaterPlan) {
            result.data.data[0].paymentOptions[0].payLaterPlan = [];
          }
          if (!result.data.data[0].paymentOptions[0].recurringSchedule) {
            result.data.data[0].paymentOptions[0].recurringSchedule = {
              period: "week", // month week data etc
              frequency: "", // number 1,2
              startDate: "",
              endDate: "",
              endType: "", // by , after
              endPayments: "", // number 1 /2
            };
          }
          this.paymentOptionData = result.data.data[0].paymentOptions[0];
          this.customFieldList = result.data.data[0].customFields;
          this.paymentDataDefault.requestTypes = [this.paymentOptionData.requestType];
        }).catch((ex) => {
          this.$vs.notify({
            title: "Request template",
            text: ex.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });
          if (ex.response.status === 404) {
            this.$router.push({ name: "payment-request-template" });
          }
        });
      this.$refs.templateCollapse.maxHeight = "none !important";
    },

    pushToCustomFields(customFieldList) {
      this.customFieldList = customFieldList;
      if (this.$refs.customCollapse) {
        this.$refs.customCollapse.maxHeight = "none !important";
      }
      this.childFormDirty = true;
    },

    async createApiTemplate() {
      const valid = await this.$validator.validate();
      if (this.errors.items.length > 0) {
        return false;
      }

      if (valid) {
        const formData = new FormData();
        if (this.id) {
          formData.append("templateId", this.id);
        }
        formData.append("merchantId", this.partnerId);
        formData.append("partnerId", this.merchantId);
        formData.append("templateName", this.templateName);
        formData.append("description", this.templateDescription);
        formData.append("templateType", this.templateType);
        formData.append("requestType", "template");
        let obj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };

        this.$vs.loading();
        this.createRequestTemplate(obj)
          .then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: result.data.title,
              text: result.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            localStorage.removeItem("prNotification");
            localStorage.removeItem("txNotification");
            localStorage.removeItem("trCheckbox");
            localStorage.removeItem("prCheckbox");
            this.isSaved = true;
            this.$router.push({ name: "payment-request-template" });
          }).catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Request template",
              text: ex.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },

    async createTemplate() {
      this.isSubmitted = true;
      const valid = await this.$validator.validate();
      if (this.paymentOptionData.requestType == "") {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.linkedCollapse.maxHeight = "none !important";
        return false;
      }
      if (this.errors.items.length > 0) {
        this.isSubmitted = false;
        return false;
      }
      if (!valid) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.linkedCollapse.maxHeight = "none !important";
        return false;
      } else {
        const formData = new FormData();

        if (this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("editedByAdmin", this.user.adminId);
          }
          formData.append("templateId", this.id);
        }
        if (!this.id) {
          if (this.user && this.user.adminId && this.user.loginBy) {
            formData.append("createdByAdmin", this.user.adminId);
          }
        }
        formData.append("merchantId", this.partnerId);
        formData.append("partnerId", this.merchantId);
        formData.append("templateName", this.templateName);
        formData.append("description", this.templateDescription);
        formData.append("linkedAccount", JSON.stringify(this.linkedAccount));
        formData.append("productConfigType", this.productConfigType);

        // making plans as array
        this.paymentOptionData.payLaterPlan = Array.isArray(this.paymentOptionData.payLaterPlan) ? this.paymentOptionData.payLaterPlan : [this.paymentOptionData.payLaterPlan];
        const selectedPlan = this.paymentDataDefault.payLaterPlans.filter((element) => { return (element.customPlanId == this.paymentOptionData.payLaterPlan[0]) });
        if (selectedPlan[0] && selectedPlan[0].displayName) {
          this.paymentOptionData.planDisplayName = selectedPlan[0].displayName;
        }

        // payment options
        formData.append("paymentOptions", JSON.stringify(this.paymentOptionData));
        formData.append("requestExpiry", JSON.stringify(this.requestExpiry));
        if (this.notifications.multipleEmailAddress && this.notifications.multipleEmailAddress.length) {
          this.notifications.multipleEmailAddress = this.notifications.multipleEmailAddress.map(item=> { return item.email || item });
        }
        formData.append("notifications", JSON.stringify(this.notifications));

        if (this.productConfigType == 'THREE_PARTY') {
          formData.append("header", JSON.stringify(this.header));
          formData.append("companyLogo", this.header.companyLogo);
          formData.append("partnerLogo", this.header.partnerLogo);
          formData.append("customText", JSON.stringify(this.customTextBlockData));
          if (this.redirectData) {
            if (this.paymentOptionData.requestOptions.includes("pay-now")) {
              this.redirectData.fail.enablepaynow = false;
            }
            formData.append("redirect", JSON.stringify(this.redirectData));
          }
          formData.append("senderDetails", JSON.stringify(this.senderDetails));
          formData.append("customFields", JSON.stringify(this.customFieldList));
        }
        formData.append("requestType", "template");
        formData.append("templateType", this.templateType);
        let obj = {
          data: formData,
          config: {
            header: {
              "Content-Type": "multipart/form-data",
            },
          },
        };

        this.$vs.loading();
        this.createRequestTemplate(obj)
          .then((result) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: result.data.title,
              text: result.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            localStorage.removeItem("prNotification");
            localStorage.removeItem("txNotification");
            localStorage.removeItem("trCheckbox");
            localStorage.removeItem("prCheckbox");
            this.isSaved = true;
            this.$router.push({ name: "payment-request-template" });
          }).catch((ex) => {
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Request template",
              text: ex.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          });
      }
    },

    getLogos() {
      this.fetchLogosByMerchantId(this.merchantId).then((res) => {
        this.logoLibrary = res.data.data;
      });
    },

    async getNotifications() {
      await this.fetchNotification()
        .then(async (res) => {
          if (res && res.data.data.prNotification && res.data.data.txNotification) {
            this.notifications.requestSenderEmail = true;
            this.notifications.prNotification = res.data.data.prNotification;
            this.notifications.txNotification = res.data.data.txNotification;
            this.transactionNotificationsCheckbox.forEmail = this.beChecked(res.data.data.txNotification, "forEmail");
            this.transactionNotificationsCheckbox.forDashboard = this.beChecked(res.data.data.txNotification, "forDashboard");
            this.prNotificationsCheckbox.forEmail = this.beChecked(res.data.data.prNotification, "forEmail");
            this.prNotificationsCheckbox.forDashboard = this.beChecked(res.data.data.prNotification, "forDashboard");
            localStorage.setItem("prNotification", JSON.stringify(res.data.data.prNotification));
            localStorage.setItem("txNotification", JSON.stringify(res.data.data.txNotification));
            localStorage.setItem("trCheckbox", JSON.stringify(this.transactionNotificationsCheckbox));
            localStorage.setItem("prCheckbox", JSON.stringify(this.prNotificationsCheckbox));
          }
        }).catch((ex) => {
          this.$vs.notify({
            title: "Notification",
            text: "We are unable to process your request at this time.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });
        });
    },

    async getGlobalSetting() {
      this.$vs.loading();
      await this.fetchSettingsByMerchantId()
        .then(async (res) => {
          if (res.data.data.companyHeader) {
            this.header = res.data.data.companyHeader;
            this.header.isDefaultHeader = true;
            this.header.partnerLogoEnabled =  this.header.partnerLogoEnabled ? this.header.partnerLogoEnabled : false;
            this.header.image = this.header.companyLogo ? this.header.companyLogo.logoImage : undefined;
            this.header.companyLogo = this.header.companyLogo ? this.header.companyLogo._id : undefined;
            this.header.partnerimage = this.header.partnerLogo ? this.header.partnerLogo.logoImage : undefined;
            this.header.partnerLogo = this.header.partnerLogo ? this.header.partnerLogo : undefined;
            this.defaultHeader = this.header;
          } else {
            this.header.isDefaultHeader = true;
            this.defaultHeader.isDefaultHeader = true;
          }

          if (res.data.data.paymentExpiry) {
            this.requestExpiry.paymentExpiry = res.data.data.paymentExpiry;
            this.requestExpiry.isDefaultExpiry = true;
            this.defaultExpiryData.isDefaultExpiry = true;
            this.defaultExpiryData.paymentExpiry = res.data.data.paymentExpiry;
          } else {
            this.requestExpiry.isDefaultExpiry = true;
            this.requestExpiry.paymentExpiry = "4";
            this.defaultExpiryData.isDefaultExpiry = true;
            this.defaultExpiryData.paymentExpiry = "4";
          }

          if (res.data.data.requestTemplateCommunication) {
            this.defaultCommunication =  { ...res.data.data.requestTemplateCommunication };
            this.defaultSender.allowOverride = res.data.data.requestTemplateCommunication.allowOverride;
            this.defaultSender.senderType = "1";
            this.defaultSender.fullname = res.data.data.requestTemplateCommunication.fullname;
            this.defaultSender.senderEmail = res.data.data.requestTemplateCommunication.senderEmail;
            this.defaultSender.senderPhone = res.data.data.requestTemplateCommunication.senderPhone;
            this.senderDetails = { ...res.data.data.requestTemplateCommunication };
            this.senderDetails.senderType = "1";
            this.globalDefaultOverride = res.data.data.requestTemplateCommunication.allowOverride;
          } else {
            this.defaultSender.senderType = "1";
            this.senderDetails.senderType = "1";
          }

          if (this.id) {
            await this.fetchTemplateDetails();
            this.simpleAdvanceBox = false;
          }
          await this.getPaylaterPlan();
          this.$vs.loading.close();
        })
        .catch((ex) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Global settings",
            text: "We are unable to process your request at this time.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right"
          });
          this.headerBind = true;
          this.expiryBind = true;
          this.senderBind = true;
          this.notifyBind = true;
          this.paymentBind = true;
          this.customFieldBind = true;
        });
      this.headerBind = true;
      this.expiryBind = true;
      this.senderBind = true;
      this.notifyBind = true;
      this.paymentBind = true;
      this.customFieldBind = true;
    },

    beChecked(value, field) {
      return value.every((el) => el[field]);
    },

    headerChange(e) {
      if (e) {
        this.header = { ...this.defaultHeader };
      } else {
        if (this.editHeader && this.editHeader.isDefaultHeader == false) {
          this.header = { ...this.editHeader };
        } else {
          this.header = {};
          this.header.isDefaultHeader = e;
        }
      }
      this.partnerToggle();
    },

    partnerToggle() {
      this.childFormDirty = true;
      this.$refs.headerCollapse.maxHeight = "none !important";
    },

    expiryChange(e) {
      this.requestExpiry = {};
      if (e) {
        this.requestExpiry.isDefaultExpiry = true;
        if (this.defaultExpiryData.paymentExpiry) {
          this.requestExpiry.paymentExpiry = this.defaultExpiryData.paymentExpiry;
        } else {
          this.requestExpiry.paymentExpiry = "3";
        }
      } else {
        if (this.pageRequestExpiry && this.pageRequestExpiry.isDefaultExpiry == false) {
          this.requestExpiry = { ...this.pageRequestExpiry };
        } else {
          this.requestExpiry.isDefaultExpiry = false;
          this.requestExpiry.paymentExpiry = "3";
        }
      }
    },

    changeSender(e) {
      this.senderDetails.senderType = e;
      if (e == "1") {
        this.senderDetails.fullname = this.defaultCommunication.fullname;
        this.senderDetails.senderPhone = this.defaultCommunication.senderPhone;
        this.senderDetails.senderEmail = this.defaultCommunication.senderEmail;
      } else if (e == "2") {
        if (this.pageSender && this.pageSender.senderType == "2") {
          this.senderDetails = { ...this.pageSender };
        } else {
          this.senderDetails.allowOverride = false;
        }
      }
      this.childFormDirty = true;
    },

    formValidation() {
      return !this.errors.any();
    },

    notifyChange(e) {
      if (Object.keys(this.pageNotifications).length > 0) {
        this.notifications = { ...this.pageNotifications };
        this.transactionNotificationsCheckbox = { ...this.pageTrNotify };
        this.prNotificationsCheckbox = { ...this.pagePrNotify };
      } else {
        this.notifications.prNotification = JSON.parse(localStorage.getItem("prNotification"));
        this.notifications.txNotification = JSON.parse(localStorage.getItem("txNotification"));
        this.prNotificationsCheckbox = JSON.parse(localStorage.getItem("prCheckbox"));
        this.transactionNotificationsCheckbox = JSON.parse(localStorage.getItem("trCheckbox"));
      }
      this.notifications.requestSenderEmail = e;
      if (e) {
        this.notifications.emailAddress = "";
      }
      this.childFormDirty = true;
    },

     changeConnector() {
      if (this.bankList.length > 0) {
        const objIndex = this.bankList.findIndex((obj) => obj.bankId === this.selectedAccount);
        this.linkedAccount = this.bankList[objIndex];
      }
    },

    onCancel() {
      this.$router.push({ name: "payment-request-template" });
    },

    activateTemplate(el) {
      if (el == "simple") {
        this.templateType = "simple";
      } else if ("advance") {
        this.templateType = "advance";
      }
    },

    configureTemplate() {
      this.templateActive = true;
      this.simpleAdvanceBox = false;
    },

    delActions(event) {
      switch (event) {
        case "delete":
          this.popupDelActive = false;
          this.deleteTemplate();
          break;
        case "cancel":
          this.popupDelActive = false;
          break;
        default:
          this.popupDelActive = false;
      }
    },

    async deleteTemplate() {
      let obj = {
        id: this.id,
        payload: { merchantId: this.partnerId, deleted: true },
      };
      this.$vs.loading();
      this.deleteRequestTemplateById(obj)
        .then((res) => {
          this.isSaved = true;
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Request template",
            text: "Template deleted successfully",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success",
          });
          this.onCancel();
        }).finally(() => this.$vs.loading.close());
    },

    async fetchPendingPR() {
      let obj = { templateId: this.id, status: "Pending" };
      this.fetchRequestTemplateByIdandStatus(obj).then((res) => {
        const count = res.data.data.length;
        if (count > 0) {
          this.warningFound = true;
          this.warningContent = `There are ${count} pending requests linked to this
            template. By deleting this template you will also cancel any pending payment
            requests sent from this template.`;
        }
        this.popupDelActive = true;
      });
    },

    checkDirty(val) {
      this.childFormDirty = val;
    },

    changeProductConfig(val) {
      this.productConfigType = val;
    },
  },
  watch: {
    async "paymentOptionData.requestType"(val) {
      this.isSubmitted = false;
    },

    id(val) {
      if (val) {
        this.simpleAdvanceBox = false;
      } else {
        this.simpleAdvanceBox = true;
      }
    },

    "errors.items"(val) {
      if (this.errors.items.length > 0) {
        this.$refs.templateCollapse.maxHeight = "none !important";
        this.$refs.linkedCollapse.maxHeight = "none !important";
        this.$refs.headerCollapse.maxHeight = "none !important";
        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }
      }
    },

    isFormDirty(latest) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    childFormDirty: {
      handler: (latest) => {
        if (latest) {
          localStorage.setItem("formFieldUpdated", "yes");
        }
      },
      flush: "post"
    }
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.childFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  async mounted() {
    await this.getNotifications();
    await this.getGlobalSetting();
    this.merchantId = JSON.parse(localStorage.user).userType == "admin" ? JSON.parse(localStorage.user).merchantId : JSON.parse(localStorage.user).partner.merchantId;
    this.getConnectorsList(this.partnerId);
    this.getLogos();
  },
};
</script>
