<template>
  <div class=" radiobox sm:justify-center radio-default">
    <div class="radio-wrapper" :class="{activeBG: isChecked}">
      <div class="title flex items-center">
        <div class="font-normal capitalize radio-label text-xl mb-0" >{{ title }}</div>
      </div>
      <vs-checkbox
        v-model="model"
        :vs-name="name"
        :vs-value="radioValue"
        class="checkbox"
        @click.native="log(radioValue)"
      />
    </div>
    <div class="desc" v-if="description">
      <p class="text-xs">{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkbox.vue",
  props: {
    title: {
      type: String,
      reqired: true,
    },
    name: {
      type: String,
      reqired: true,
    },
    radioValue: {
      reqired: true,
    },
    value: {
      reqired: true,
    },
    description: {
      type: String,
      reqired: false,
    },
    isChecked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modelValue: "",
    };
  },
  methods: {
    log(e) {},
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  mounted() {},
  beforeUpdate() {
  },
};
</script>