<template>
  <vx-card class="accordin-card mt-5">
    <vs-collapse accordion>
      <vs-collapse-item ref="senderCollapse">
        <div slot="header">
          <h3 class="text-left card-title">Contact details</h3>
        </div>
        <p class="mb-8">
          Set the contact details you want customers to see in the body of emails and SMS messages they receive related to this payment request template.
        </p>
        <vs-row class="sender-info mb-10">
          <div class="lg:mr-6 md:mr-5 sm:mr-5 mr-0 space-below">
            <div class="radio-card space-below " @click="activate(1)" :class="{ active: active_el == 1 }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10">
                  <p class="text-xl radio-label">Default</p>
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex items-center justify-center">
                  <vs-radio v-model="senderType" vs-value="1" vs-name="user"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Use default contact details as per global settings</span>
                </vs-col>
              </vs-row>
            </div>
          </div>

          <div>
            <div class="radio-card " @click="activate(2)" :class="{ active: active_el == 2 }">
              <vs-row>
                <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10">
                  <p class="text-xl radio-label">Custom</p>
                </vs-col>
                <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex items-center justify-center">
                  <vs-radio v-model="senderType" vs-value="2" vs-name="user"></vs-radio>
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <span class="radio-info">Set up custom contact details for this template</span>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </vs-row>

        <vs-row id="no-disable" :class="{ disabledinputs : senderDisabled == true }">
          <vs-row>
            <vs-col vs-w="4" vs-lg="4" vs-sm="12">
              <div class="mb-5 mt-5">
                <label :class="{ disabledlabel : senderDisabled == true }">Full Name <span class="mid-blue">*</span></label>
                <vs-input
                  class="w-full"
                  v-validate="'required|max:100'"
                  size="large"
                  v-model="senderData.fullname"
                  name="fullname"
                  id="fullname"
                  :disabled="senderDisabled"
                  />
                <span class="text-danger text-sm" v-show="errors.has('fullname')">{{ errors.first("fullname") }}</span>
              </div>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-w="4" vs-lg="4" vs-sm="12" class="lg:mr-6 md:mr-5">
              <div class="mb-5 mt-5">
                <label class="disabled-label">Email Address </label>
                <vs-input
                  class="w-full"
                  v-validate="'required|email|min:3'"
                  size="large"
                  v-model="senderData.senderEmail"
                  name="senderEmail"
                  id="senderEmail"
                  :disabled="senderDisabled"
                  />
                <span class="text-danger text-sm" v-show="errors.has('senderEmail')">{{ errors.first("senderEmail") }}</span>
              </div>
            </vs-col>

            <vs-col vs-w="4" vs-lg="4" vs-sm="12">
              <div class="mb-5 mt-5">
                <label class="disabled-label">Phone Number </label> <br>
                <the-mask
                  :class=" senderPhone ? 'vs-inputx vs-input--input large' : 'vs-inputx vs-input--input large'"
                  :mask="[checkPhoneNumber]"
                  v-model="senderData.senderPhone"
                  masked
                  name="senderPhone"
                  ref="senderPhone"
                  id="senderPhone"
                  :disabled="senderDisabled"
                  v-validate="{
                    required: true,
                    regex:
                      /^(^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3})|(^1300[\s.-]\d{3}[\s.-]\d{3})|(^1800[\s.-]\d{3}[\s.-]\d{3})|(^13[\s.-]\d{2}[\s.-]\d{2}$)|(^02[\s.-]\d{4}[\s.-]\d{4})|(^03[\s.-]\d{4}[\s.-]\d{4})|(^07[\s.-]\d{4}[\s.-]\d{4})|(^08[\s.-]\d{4}[\s.-]\d{4})/,
                  }"
                  />
                <br>
                <span class="text-danger text-sm" v-show="errors.has('senderPhone')">{{ errors.first("senderPhone") }}</span>
              </div>
            </vs-col>
          </vs-row>

          <div class="mt-8 mb-5 flex flex-row">
            <div>
              <vs-checkbox v-model="senderData.allowOverride"></vs-checkbox>
            </div>
            <div>
              <span class="info text-black text-base font-normal">Individual users can to choose to use their own details as the contact details when they send requests from this template</span>
            </div>
          </div>
        </vs-row>
      </vs-collapse-item>
    </vs-collapse>
  </vx-card>
</template>

<script>
import { TheMask } from "vue-the-mask";
import { Validator } from "vee-validate";

const dictionary = {
  en: {
    attributes: {
      fullname: "full name",
      senderEmail: "email address",
      senderPhone: "phone number",
    },
  },
};

Validator.localize(dictionary);

export default {
  name: "SenderInformation",
  props: ["senderData", "isSubmitted"],
  components: { TheMask },
  data() {
    return {
      active_el: "1",
      senderType: "1",
      fullName: "",
      senderEmail: "",
      senderPhone: "",
      senderDisabled: true,
    };
  },
  computed: {
    checkPhoneNumber() {
      let mask = '#### ### ###';

      if (this.senderData.senderPhone && this.senderData.senderPhone.length > 0) {
        let numLength = this.senderData.senderPhone.length;
        let value1 = '';
        let value2 = '';

        if (numLength >= 2) {
          value1 = this.senderData.senderPhone.substring(0,2);
          if (value1 === '13') {
            value2 = this.senderData.senderPhone.substring(0,4);
          }
        }

        if (['02', '03', '07', '08'].includes(value1)) {
          mask = "## #### ####";
        } else {
          mask = "#### ### ###";
        }

        if (value1 === '13') {
          if (parseInt(value2) >= 1300 || value2 === '130' || value2 === '13 0') {
            mask = "#### ### ###";
          } else {
            mask = "## ## ##";
          }
        }
      }

      return mask;
    }
  },

  mounted() {
    if (this.globalDefaultOverride == false) {
      this.senderDisabled = true;
    }

    this.active_el = this.senderData.senderType;
    this.senderType = this.senderData.senderType;
    this.senderDisabled = (this.senderType == "1");
  },
  methods: {
    activate(el) {
      this.active_el = el;
      this.senderType = el;
      this.senderDisabled = (el == "1");
      this.$refs.senderCollapse.maxHeight = "none !important";
      this.$emit("changeSender", el);
    },
    
    async startValidating() {
      await this.$validator.validate();
    },
  },
  watch: {
    senderData: async function(val) {
      if (val) {
        this.$refs.senderCollapse.maxHeight = "none !important";
      }
    },

    async isSubmitted() {
      this.startValidating();
    },

    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    }
  }
};
</script>
