<template>
  <div class="req-expiry">
    <vs-row class="items-center">
      <vs-col class="lg:mr-6 md:mr-5 sm:mr-5 mr-0 w-auto">
        <div class="radio-card space-below" @click="activate(true)" :class="{ active: active_el == true }">
          <vs-row>
            <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Default</vs-col>
            <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center">
              <vs-radio v-model="defaultExpiry" vs-value="1" vs-name="expiry"></vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
              <span class="radio-info">Use default expiry time as set in global settings</span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col class="w-auto" >
        <div class="radio-card" @click="activate(false)" :class="{ active: active_el == false }">
          <vs-row>
            <vs-col vs-w="10" vs-sm="10" vs-lg="10" vs-xs="10" class="radio-label">Custom</vs-col>
            <vs-col vs-w="2" vs-sm="2" vs-lg="2" vs-xs="2" class="flex justify-center">
              <vs-radio v-model="defaultExpiry" vs-value="0" vs-name="expiry"></vs-radio>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
              <span class="radio-info">Configure custom expiry time for this template</span>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
    <hr class="line-hr my-8">
    <vs-row>
      <div class=" w-full" :class="{ disabled: requestDisabled == true }">
        <div class="w-full">
          <vs-row >
            <div class="lg:mr-10 md:mr-10 sm:mr-2 days-slider">
              <vs-slider color="primary" text-fixed=" Days" v-model="paymentExpiry" max="90" :disabled="requestDisabled" @change="expiryChange" />
            </div>
            <vs-input
              type="number"
              min="3"
              max="90"
              id="paymentExpiry"
              style="font-size:16px;height:30px;"
              v-validate="'required|numeric|between:3,90'"
              class="daysInput col-sm-4"
              name="paymentExpiry"
              v-model="paymentExpiry"
              :disabled="requestDisabled"
              @change="expiryChange"
            >
            </vs-input>
            <span class="days text-base ml-2 font-normal flex items-end" style="height:30px;"> days</span>
          </vs-row>
          <span class="text-danger text-base mb-5" v-show="errors.has('paymentExpiry')">{{ errors.first("paymentExpiry") }}</span>
        </div>
      </div>
    </vs-row>
  </div>
</template>
<script>
import { Validator } from 'vee-validate'

const dictionary = {
  en: {
    attributes: {
      paymentExpiry: "request expiry time",
    },
  },
};
Validator.localize(dictionary);

export default {
  name: "RequestExpiry",
  props: ["expiryData"],
  data() {
    return {
      active_el: true,
      requestDisabled: true,
      defaultExpiry: true,
      paymentExpiry: 3
    };
  },
  mounted() {
    this.active_el = this.expiryData.isDefaultExpiry;
    this.defaultExpiry = this.expiryData.isDefaultExpiry;
    if (this.expiryData.paymentExpiry) {
      this.paymentExpiry = this.expiryData.paymentExpiry;
    }
    this.requestDisabled = this.expiryData.isDefaultExpiry;
  },
  methods: {
    activate(el) {
      this.active_el = el;
      this.defaultExpiry = el;
      this.requestDisabled = el;
      this.expiryData.isDefaultExpiry = el;
      this.$emit("changeExpiry", el);
    },

    expiryChange() {
      this.expiryData.paymentExpiry = this.paymentExpiry;
      if (this.paymentExpiry < 3) {
        this.paymentExpiry = 3;
        this.expiryData.paymentExpiry = 3;
      } else if (this.paymentExpiry > 90) {
        this.paymentExpiry = 90;
        this.expiryData.paymentExpiry = 90;
      }
    }
  },
  watch: {
    expiryData: async function(val) {
      if (val) {
        this.expiryData.paymentExpiry = val.paymentExpiry;
        this.paymentExpiry = val.paymentExpiry;
      }
    },
    isFormDirty(val) {
      if (val) {
        this.$emit("checkDirty", this.isFormDirty);
      }
    }
  }
}
</script>